const Resume = () => {
	return (
		<iframe
			src='/resume.pdf'
			className='w-full h-[calc(100vh-7.5rem)] pt-20'
			title='Resume'
		/>
	);
};

export default Resume;
