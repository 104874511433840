import NavBar from './NavBar/NavBar';
import { createBrowserRouter, RouterProvider, Outlet } from 'react-router-dom';
import LandingPage from './LandingPage/LandingPage';
import Particles from 'react-tsparticles';
import { loadFull } from 'tsparticles';
import { useCallback } from 'react';
import Resume from './Resume';

const Layout = () => {
	const particlesInit = useCallback(async (engine) => {
		await loadFull(engine);
	}, []);

	return (
		<>
			<Particles
				id='tsparticles'
				init={particlesInit}
				className='fixed inset-0 -z-10'
				options={{
					background: {
						color: {
							value: '#121212',
						},
					},
					fpsLimit: 120,
					interactivity: {
						events: {
							onClick: {
								enable: true,
								mode: 'push',
							},
							onHover: {
								enable: true,
								mode: 'repulse',
							},
						},
						modes: {
							push: {
								quantity: 4,
							},
							repulse: {
								distance: 100,
								duration: 0.4,
							},
						},
					},
					particles: {
						color: {
							value: '#B0E0E6',
						},
						links: {
							color: '#B0E0E6',
							distance: 150,
							enable: true,
							opacity: 0.5,
							width: 1,
						},
						move: {
							direction: 'none',
							enable: true,
							outModes: {
								default: 'bounce',
							},
							random: false,
							speed: 6,
							straight: false,
						},
						number: {
							density: {
								enable: true,
							},
							value: 100,
						},
						opacity: {
							value: 0.5,
						},
						shape: {
							type: 'triangle',
						},
						size: {
							value: { min: 1, max: 5 },
						},
					},
					detectRetina: true,
				}}
			/>
			<div className='min-h-screen w-full md:w-1/2 mx-auto font-pixel text-sm'>
				<NavBar />
				<Outlet />
			</div>
		</>
	);
};

const router = createBrowserRouter([
	{
		element: <Layout />,
		children: [
			{
				path: '/',
				element: <LandingPage />,
			},
			{
				path: '/resume',
				element: <Resume />,
			},
		],
	},
]);

const App = () => {
	return <RouterProvider router={router} />;
};

export default App;
