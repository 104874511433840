import { TypeAnimation } from 'react-type-animation';
import { FaGithub, FaLinkedin } from 'react-icons/fa';

const LandingPage = () => {
	return (
		<div className='flex flex-col items-center h-full pt-20 md:pt-48'>
			<TypeAnimation
				sequence={["Welcome! I am Christian May! I'm a full stack developer"]}
				speed={50}
				className='text-lg md:text-3xl font-bold text-center px-4 mx-4'
			/>
			<section
				id='about'
				className='min-h-fit px-4 my-10 md:my-48 scroll-mt-20 max-w-full md:max-w-3xl mx-auto'
			>
				<h1 className=' text-xl md:text-2xl font-bold text-center mb-10'>
					About Me
				</h1>
				<p className=' text-base md:text-lg font-bold text-center bg-[#1E1E1E] border-2 border-dark rounded-lg p-4 md:p-10'>
					I'm a passionate developer who loves to create and build new things.
					My experience lies in developing web applications using React/Redux.
					My love for programming came when I first built a discord bot using
					discord.py. While I was doing that, I fell in love with the process of
					bringing ideas to life with code. When I'm not coding, you can find me
					watching anime, playing video games, or sleeping. I am always looking
					to expand my knowledge and skills, so please feel free to reach out!
				</p>
				<h3 className='text-lg md:text-xl font-bold text-center mb-4 mt-10'>
					Technologies:
				</h3>
				<div className='flex flex-wrap items-center justify-center'>
					<span>&nbsp;&nbsp;</span>
					<img
						align='center'
						src='https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg'
						className='h-10'
						alt='javascript logo'
					/>
					<span>&nbsp;&nbsp;</span>
					<img
						align='center'
						src='https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg'
						className='h-10'
						alt='react logo'
					/>
					<span>&nbsp;&nbsp;</span>
					<img
						align='center'
						src='https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-original.svg'
						className='h-10'
						alt='html5 logo'
					/>
					<span>&nbsp;&nbsp;</span>
					<img align='center' src='/css.svg' className='h-10' alt='css3 logo' />
					<span>&nbsp;&nbsp;</span>
					<img
						align='center'
						src='https://cdn.jsdelivr.net/gh/devicons/devicon/icons/python/python-original.svg'
						className='h-10'
						alt='python logo'
					/>
					<span>&nbsp;&nbsp;</span>
					<img
						align='center'
						src='/docker-mark-blue.png'
						className='h-10'
						alt='docker logo'
					/>
					<span>&nbsp;&nbsp;</span>
					<img
						align='center'
						src='/Postgresql_elephant.svg'
						className='h-10'
						alt='postgresql logo'
					/>
					<span>&nbsp;&nbsp;</span>
					<img
						align='center'
						src='/express-js.png'
						className='h-10'
						alt='express logo'
					/>
				</div>
			</section>

			<section
				id='projects'
				className='min-h-[70vh] px-4 mx-auto flex flex-col justify-center w-full max-w-full md:max-w-3xl'
			>
				<h1 className=' text-xl md:text-2xl font-bold text-center mb-10'>
					Projects
				</h1>
				<div className='flex flex-col items-stretch gap-6 mb-10 w-full'>
					<div className='flex flex-col text-lg md:text-xl font-bold cursor-pointer border-2 border-dark rounded-lg p-6 bg-[#1E1E1E] transition-colors duration-300 w-full'>
						<h1 className='text-2xl md:text-3xl font-bold text-center mb-4'>
							MangaVault
						</h1>
						<p className='text-base md:text-lg flex-grow'>
							MangaVault is the ultimate platform for anime and manga
							enthusiasts, offering access to an extensive database of anime and
							manga entries. Our website empowers users to create, customize,
							and manage personalized lists tailored to their interests—whether
							it's tracking completed series, curating watchlists, or
							highlighting hidden gems.
						</p>
						<div className='flex flex-wrap gap-2 bg-dark rounded-lg p-2 justify-center mt-2'>
							<img
								align='center'
								src='https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg'
								className='h-10'
								alt='javascript logo'
							/>
							<img
								align='center'
								src='https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg'
								className='h-10'
								alt='react logo'
							/>
							<img
								align='center'
								src='https://raw.githubusercontent.com/reduxjs/redux/master/logo/logo.svg'
								className='h-10'
								alt='redux logo'
							/>
							<img
								align='center'
								src='/tailwindcss.svg'
								className='h-10'
								alt='tailwindcss logo'
							/>
							<img
								align='center'
								src='/express-js.png'
								className='h-10'
								alt='express logo'
							/>
						</div>
						<div className='flex justify-center gap-4 mt-4 text-sm'>
							<a
								href='https://github.com/Mayboyzz/Capstone-Project/tree/dev'
								target='_blank'
								rel='noreferrer'
								className='cursor-pointer hover:underline'
							>
								Github
							</a>
							<a
								href='https://mangavault.onrender.com/'
								target='_blank'
								rel='noreferrer'
								className='cursor-pointer hover:underline'
							>
								Live
							</a>
						</div>
					</div>
					<div className='flex flex-col text-lg md:text-xl font-bold cursor-pointer border-2 border-dark rounded-lg p-6 bg-[#1E1E1E] transition-colors duration-300 w-full'>
						<h1 className='text-2xl md:text-3xl font-bold text-center mb-4'>
							Wander-Home
						</h1>
						<p className='text-base md:text-lg flex-grow'>
							Developed an Airbnb clone as a school project, enabling users to
							list their properties, explore available spots, and leave detailed
							reviews for places they've stayed. The platform focuses on
							creating a user-friendly interface for seamless property listing
							and discovery, along with a review system to foster community
							engagement and trust.
						</p>
						<div className='flex flex-wrap gap-2 bg-dark rounded-lg p-2 justify-center mt-2'>
							<img
								align='center'
								src='https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg'
								className='h-10'
								alt='javascript logo'
							/>
							<img
								align='center'
								src='https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg'
								className='h-10'
								alt='react logo'
							/>
							<img
								align='center'
								src='https://raw.githubusercontent.com/reduxjs/redux/master/logo/logo.svg'
								className='h-10'
								alt='redux logo'
							/>
							<img
								align='center'
								src='/tailwindcss.svg'
								className='h-10'
								alt='tailwindcss logo'
							/>
							<img
								align='center'
								src='/express-js.png'
								className='h-10'
								alt='express logo'
							/>
						</div>
						<div className='flex justify-center gap-4 mt-4 text-sm'>
							<a
								href='https://github.com/Mayboyzz/wander-home'
								target='_blank'
								rel='noreferrer'
								className='cursor-pointer hover:underline'
							>
								Github
							</a>
							<a
								href='https://wander-home.onrender.com/'
								target='_blank'
								rel='noreferrer'
								className='cursor-pointer hover:underline'
							>
								Live
							</a>
						</div>
					</div>
				</div>
			</section>

			<section
				id='socials'
				className='flex flex-col items-center my-10 md:my-40 px-4 w-full max-w-full md:max-w-3xl'
			>
				<h1 className=' text-xl md:text-2xl font-bold mb-6'>Socials</h1>
				<div className='flex flex-col md:flex-row items-center justify-center gap-6 md:gap-4'>
					<a
						href='https://github.com/Mayboyzz'
						target='_blank'
						rel='noreferrer'
						className=' text-lg md:text-xl font-bold hover:text-textSecondary cursor-pointer flex items-center transition-colors duration-300'
					>
						<FaGithub className='text-2xl md:text-xl' />
						<h1 className='ml-2 hover:underline'>Github</h1>
					</a>
					<a
						href='https://linkedin.com/in/christian-may-41277718a'
						target='_blank'
						rel='noreferrer'
						className=' flex cursor-pointer items-center font-bold text-lg transition-colors duration-300 hover:text-textSecondary md:text-xl'
					>
						<FaLinkedin className='text-2xl md:text-xl' />
						<h1 className='ml-2 hover:underline'>LinkedIn</h1>
					</a>
				</div>
			</section>
		</div>
	);
};

export default LandingPage;
