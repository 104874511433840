import { useState } from 'react';
import { FiMenu } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
const NavBar = () => {
	const [isOpen, setIsOpen] = useState(false);
	const navigate = useNavigate();

	const handleClick = () => {
		setIsOpen(!isOpen);
	};

	const scrollToSection = (sectionId) => {
		const element = document.getElementById(sectionId);
		if (element) {
			element.scrollIntoView({ behavior: 'smooth' });
			setIsOpen(false); // Close menu after clicking
		}
	};

	return (
		<nav className='p-2 fixed top-0 z-50 w-full md:w-1/2'>
			<div className='flex items-center relative'>
				<h1
					className='ml-auto text-xl font-bold cursor-pointer hidden md:block'
					onClick={handleClick}
					onKeyDown={handleClick}
				>
					Menu
				</h1>
				<FiMenu
					className='font-bold ml-auto cursor-pointer block md:hidden text-5xl'
					onClick={handleClick}
					onKeyDown={handleClick}
				/>
				{isOpen && (
					<div className='absolute top-full right-0 mt-2 bg-[#1E1E1E] border border-gray-200 rounded-md shadow-lg'>
						<ul className='py-1'>
							<li
								className='px-4 py-2 hover:bg-[#2D2D2D] cursor-pointer'
								onClick={() => navigate('/')}
								onKeyDown={() => navigate('/')}
							>
								Home
							</li>
							<li
								className='px-4 py-2 hover:bg-[#2D2D2D] cursor-pointer'
								onClick={() => scrollToSection('about')}
								onKeyDown={() => scrollToSection('about')}
							>
								About me
							</li>
							<li
								className='px-4 py-2 hover:bg-[#2D2D2D] cursor-pointer'
								onClick={() => scrollToSection('projects')}
								onKeyDown={() => scrollToSection('projects')}
							>
								Projects
							</li>
							<li
								className='px-4 py-2 hover:bg-[#2D2D2D] cursor-pointer'
								onClick={() => scrollToSection('socials')}
								onKeyDown={() => scrollToSection('socials')}
							>
								Socials
							</li>
							<li
								className='px-4 py-2 hover:bg-[#2D2D2D] cursor-pointer'
								onClick={() => {
									navigate('/resume');
									setIsOpen(false);
								}}
								onKeyDown={() => {
									navigate('/resume');
									setIsOpen(false);
								}}
							>
								Resume
							</li>
						</ul>
					</div>
				)}
			</div>
		</nav>
	);
};

export default NavBar;
